import React from "react";
import "../scss/capabilitiesCard.scss";
import { IneractiveBtn } from "./BtnInteractive";
import { useNavigate, useLocation } from "react-router-dom";
import whiteArrow from "../assets/whiteArrow.svg"

import {NAV_SUB_ROUTES } from "../constants/routes";

const CapabilitiesCard = ({ solution, id }) => {
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  function handleKnowMoreBtn(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAV_SUB_ROUTES.SOLUTION_DETAIL, search: newSearch });
    window.location.reload();
  }
  return (
    <div className="capabilitiesCard">
      <div className="capabilitiesCardTitle">{solution.title}</div>
      <div className="capabilitiesCardText">{solution.description}</div>

      {/* <div
        onClick={() => handleKnowMoreBtn(id + 1)}
        className="capabilitiesCardBtn"
      >
        <IneractiveBtn
          title={"Know More"}
          btnType={"noFill"}
          arrowIcon={whiteArrow}
        />
      </div> */}
    </div>
  );
};

export default CapabilitiesCard;
