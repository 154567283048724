export const onVisible = (domElement) => {

    console.log("element", domElement)
    return new Promise(resolve => {
    const o = new IntersectionObserver(([entry]) => {
      resolve(entry.intersectionRatio === 1);
      o.disconnect();
    });
    o.observe(domElement);
  });
}

export const bannerDescription =
  "We specialize in turning ideas into market-ready digital products, combining deep domain expertise with GenAI, cloud-first strategies, and intelligent automation. Whether it’s an AI-powered SaaS, an enterprise-grade platform, or a GenAI application—we bring your vision to life with precision and speed.";
